import React, { Component } from "react";

class Quote extends Component {
  render() {
    return (
      <section
        id="testimonials"
        className="block bg-dark spacer p-top-xl p-bottom-xl"
      >
        <div className="wrapper">
          <div className="title">
            <h6 className="text-primary text-uppercase">Words of wisdom</h6>
          </div>

          <div className="description text-tertiary">
            <h2>Insights from a technology visionary</h2>
          </div>

          <div className="adv-slider-reviews text-tertiary">
            <div className="adv-slider-reviews-img">
              <img src="assets/img/demo/11_img.png" alt="Icon" />
            </div>

            <div className="adv-swiper-container reviews-text">
              <div className="adv-swiper-wrapper reviews-text-items">
                <div className="adv-swiper-slide reviews-text-item">
                  <div className="reviews-text-item-content">
                    <h3>
                      You've got to start with the customer experience and work
                      back toward the technology, not the other way around
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center thumbs">
              <div className="reviews-results">
                <h6 className="reviews-name" id="reviews-name">
                  Steve Jobs
                </h6>
                <p className="reviews-positions" id="reviews-positions">
                  Apple Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Quote;
