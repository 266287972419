import React from "react";

import ContactsForm from "../../components/form/ContactForm";

const Contacts = () => {
  return (
    <section id="contact" className="block bg-white-before spacer p-top-xl-2">
      <div id="contacts-0" className="wrapper spacer p-top-xl-2">
        <div className="title">
          <h6 className="text-primary text-uppercase">Contact</h6>
        </div>

        <div className="title-opacity">
          <div className="title-opacity-text">Contact</div>
        </div>

        <div className="description-lg">
          <h2>Have a question, suggestion or feedback?</h2>
        </div>
      </div>

      <div
        className="width-img spacer p-top-xl p-bottom-xl"
        style={{
          backgroundImage: `url('assets/img/demo/18_img.png')`,
        }}
      >
        <div className="wrapper">
          <div id="contact-form">
            <div className="title">
              <h3 className="pb-0">I'd like to hear from you!</h3>
            </div>

            <div className="description-lg spacer p-top-lg">
              <p>
                Whether you have a question, a suggestion, or just want to say
                hi, please don't hesitate to get in touch using the form below.
                I'll do my best to get back to you as soon as possible. Thank
                you for your interest in my website and I look forward to
                connecting with you!
              </p>
            </div>

            <div className="spacer p-top-lg">
              <ContactsForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
