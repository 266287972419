import React from "react";

const PageTitleHome = () => {
  return (
    <section
      id="page-title"
      className="block with-img with-service-items"
      style={{
        backgroundImage: `url('assets/img/placeholder/1920x1000.jpg')`,
      }}
    >
      <div className="wrapper d-flex flex-column justify-content-center">
        <div className="page-title-body page-title-body-space-left">
          <div className="title">
            <h1 className="large">
              Transforming your ideas
              <br />
              into intuitive Mobile apps
            </h1>
          </div>

          <div className="description spacer p-top-lg">
            <p>
              I bring your vision to life with user-centric design and seamless
              functionality.
            </p>
          </div>

          <div className="spacer p-top-lg no-space">
            <a href="#services" className="btn btn-outline-tertiary">
              Learn More
            </a>
          </div>
        </div>

        <div className="page-title-footer full-width">
          <div className="d-flex justify-content-between">
            <a href="#services" className="scroll-to-id scroll-animation">
              <i className="malex-icon-long-arrow-down i-large-2"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="page-title-bg-color"></div>
    </section>
  );
};

export default PageTitleHome;
