import React from "react";

const Copyright = () => {
  return (
    <div className="copyright">
      <p>© {new Date().getFullYear()} Jayvee - BTW BE 0795.171.752</p>
    </div>
  );
};

export default Copyright;
