import React from "react";

import NewsHomeItemsData from "../../data/projects/projectsHomeItems";

const Projects = () => {
  return (
    <section
      id="projects"
      className="block spacer p-top-xl-2 m-top-xl-2 p-bottom-xl"
    >
      <div className="wrapper">
        <div className="title">
          <h6 className="text-primary text-uppercase">Projects</h6>
        </div>

        <div className="title-opacity">
          <div className="title-opacity-text">Projects</div>
        </div>

        <div className="description">
          <h2>A showcase of my successful mobile app projects</h2>
        </div>

        <div className="blog-shortcode">
          <div className="row gutter-width-md with-pb-md spacer p-top-lg">
            {NewsHomeItemsData &&
              NewsHomeItemsData.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                  >
                    <div className="card card-post">
                      <div className="card-top position-relative">
                        <a
                          title={item.title}
                          href={
                            item.link
                              ? process.env.PUBLIC_URL + item.link
                              : undefined
                          }
                        >
                          <div className="img object-fit overflow-hidden">
                            <div className="object-fit-cover transform-scale-h">
                              <img
                                className="card-top-img"
                                src={item.imgSrc}
                                alt={item.title}
                              />
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="card-body">
                        <h5 className="card-title">
                          <a
                            title={item.title}
                            href={process.env.PUBLIC_URL + item.link}
                          >
                            {item.title}
                          </a>
                        </h5>

                        <p className="card-text">{item.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
