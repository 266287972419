import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import HomeHeader from "../blocks/header/HomeHeader";
import Footer from "../blocks/footer/Footer";

import PageTitleHome from "../blocks/page-title/PageTitleHome";
import ServicesHome from "../blocks/services/ServicesHome";
import AboutMe from "../blocks/about/AboutMe";
import AboutImg from "../blocks/about/AboutImg";
import Clients from "../blocks/clients/Clients";
import Quote from "../blocks/quote/Quote";
import Projects from "../blocks/projects/Projects";
import Contact from "../blocks/contact/Contact";

const Home = () => {
  document.body.classList.add("home");
  document.body.classList.add("header-absolute-true");
  document.body.classList.add("header-fixed-true");

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Jayvee</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <HomeHeader logoColor="light" />

      <main id="main" className="site-main content-no-spacing">
        <div className="content">
          <div className="clearfix">
            <PageTitleHome />

            <ServicesHome />

            <section id="about-me" className="block spacer p-top-xl-2">
              <AboutMe />
            </section>

            <div id="img" className="block spacer p-top-lg wrapper-normal">
              <AboutImg />
            </div>

            <Projects />

            <div id="clients" className="block bg-white spacer m-top-xl">
              <Clients />
            </div>

            <Quote />

            <Contact />
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Home;
