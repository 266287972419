import React from "react";

const HeaderMenuPrimary = () => {
  return (
    <div className="menu">
      <nav className="menu-primary">
        <ul className="nav">
          <li className="nav-item">
            <a title="Services" href={process.env.PUBLIC_URL + "/#services"}>
              Services
            </a>
          </li>

          <li className="nav-item">
            <a title="About Me" href={process.env.PUBLIC_URL + "/#about-me"}>
              About me
            </a>
          </li>

          <li className="nav-item">
            <a title="Projects" href={process.env.PUBLIC_URL + "/#projects"}>
              Projects
            </a>
          </li>

          <li className="nav-item">
            <a title="Contacts" href={process.env.PUBLIC_URL + "/#contact"}>
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HeaderMenuPrimary;
