import React from "react";

const ServicesHome = () => {
  return (
    <section id="services" className="block spacer p-bottom-xl-2">
      <div className="wrapper">
        <div className="row gutter-width-md with-pb-md service-items">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="service">
              <div className="service-icon">
                <i className="malex-icon-diamond"></i>
              </div>

              <h4 className="service-t-head">Custom mobile app development</h4>

              <p className="service-description">
                Developing a mobile app from scratch, based on your specific
                requirements and preferences.
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="service">
              <div className="service-icon">
                <i className="malex-icon-wrench"></i>
              </div>

              <h4 className="service-t-head">App maintenance & support</h4>

              <p className="service-description">
                Ensure that your app continues to function properly and remains
                up to date with the latest operating systems and device
                features.
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="service">
              <div className="service-icon">
                <i className="malex-icon-idea"></i>
              </div>

              <h4 className="service-t-head">
                Consulting and strategy services
              </h4>

              <p className="service-description">
                Unique insights and expertise when it comes to app development
                best practices, user experience and technology trends.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesHome;
