import React from "react";

const HomeModalMenu = (props) => {
  return (
    <nav className="menu-primary">
      <ul className="nav">
        <li className="nav-item">
          <a
            title="Services"
            href={process.env.PUBLIC_URL + "/#services"}
            onClick={props.close}
          >
            Services
          </a>
        </li>

        <li className="nav-item">
          <a
            title="About me"
            href={process.env.PUBLIC_URL + "/#about-me"}
            onClick={props.close}
          >
            About me
          </a>
        </li>

        <li className="nav-item">
          <a
            title="Projects"
            href={process.env.PUBLIC_URL + "/#projects"}
            onClick={props.close}
          >
            Projects
          </a>
        </li>

        <li className="nav-item">
          <a
            title="Contact"
            href={process.env.PUBLIC_URL + "/#contact"}
            onClick={props.close}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default HomeModalMenu;
